<template>
  <div v-if="permission.access">
    <div class="row">
      <div class="col-md-8">
        <b-card title="Link Info List">
          <b-card-text>
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th class="text-center">Sl</th>
                    <th class="text-center">Icon</th>
                    <th>Name</th>
                    <th class="text-center">Status</th>
                    <th
                      class="text-center"
                      v-if="permission.edit || permission.delete"
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(urlInfo, index) in urlInfos" :key="index">
                    <td class="align-middle text-center">
                      {{ 1 + index }}
                    </td>
                    <td class="align-middle text-center">
                      <img height="40" :src="urlInfo.icon" alt="" />
                    </td>
                    <td><b></b>{{ urlInfo.name }}</td>
                    <td class="align-middle text-center">
                      <span
                        class="badge"
                        :class="
                          urlInfo.status == 'Active'
                            ? 'badge-success'
                            : 'badge-danger'
                        "
                      >
                        {{ urlInfo.status }}
                      </span>
                    </td>
                    <td
                      class="align-middle text-center"
                      v-if="permission.edit || permission.delete"
                    >
                      <button
                        v-if="permission.edit"
                        class="btn btn-primary btn-sm"
                        @click="Edit(urlInfo.id)"
                      >
                        <feather-icon icon="EditIcon" size="16" />
                      </button>

                      <button
                        v-if="permission.delete"
                        class="btn btn-danger btn-sm ml-1"
                        @click="Delete(urlInfo.id)"
                      >
                        <feather-icon icon="TrashIcon" size="16" />
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-card-text>
        </b-card>
      </div>
      <div class="col-md-4" v-if="permission.create || form.id != null">
        <b-card :title="(!form.id ? 'Create' : 'Update') + ' Link Info'">
          <b-form @submit.prevent="!form.id ? Create() : Update(form.id)">
            <b-row class="justify-content-center">
              <b-col cols="12">
                <label> Name </label>
                <Input
                  v-model="form.name"
                  placeholder="Write Link Name"
                  style="width: 100%"
                  type="text"
                />
                <has-error :form="form" field="name"></has-error>
              </b-col>
              <b-col cols="12">
                <label>icon</label>
                <b-form-file
                  id="icon"
                  :state="form.icon ? Boolean(form.icon) : null"
                  placeholder="Upload Icon..."
                  @change="loadImage($event)"
                  accept=".jpeg,.png,.jpg"
                  ref="fileInput"
                />

                <has-error :form="form" field="icon"></has-error>
              </b-col>

              <b-col cols="12">
                <div
                  class="d-flex justify-content-between align-items-center mt-2"
                >
                  <b-form-checkbox
                    name="status"
                    style="margin-top: 5px"
                    v-model="form.status"
                    switch
                    inline
                  />
                  <div>
                    <b-button
                      variant="primary"
                      type="submit"
                      :disabled="form.busy"
                    >
                      {{ form.id ? "Update" : "Create" }}
                    </b-button>
                    <b-button variant="danger" @click="Clear" class="ml-1">
                      Clear
                    </b-button>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BMediaBody,
  BMediaAside,
  BMedia,
  BFormFile,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
} from "bootstrap-vue";
import { HasError, AlertError } from "vform/src/components/bootstrap5";

export default {
  data() {
    return {
      form: new Form({
        id: null,
        icon: null,
        name: null,
        status: true,
      }),
      urlInfos: [],
    };
  },
  components: {
    BCard,
    BCardText,
    BLink,
    BMedia,
    BFormFile,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BMediaAside,
    BMediaBody,
    HasError,
    AlertError,
  },
  mounted() {
    this.getResults();
  },
  methods: {
    getResults() {
      axios
        .get("/app/url/info")
        .then((res) => {
          this.urlInfos = res.data.data;
        })
        .catch((e) => {
          this.e(e.response.data.message);
        });
    },

    loadImage(e) {
      this.form.icon = e.target.files[0];
    },

    Clear() {
      this.form.id = null;
      this.form.name = null;
      this.form.status = true;
      this.form.icon = null;
      if (this.$refs.fileInput.files.length > 0) {
        this.$refs.fileInput.files.pop();
      }
    },

    Create() {
      this.form
        .post("/app/url/info")
        .then((res) => {
          this.getResults();
          this.Clear();
          this.s(res.data.message);
          this.$refs.fileInput.files.pop();
        })
        .catch((e) => {
          this.e(e.response.data.message);
        });
    },
    Update(id) {
      this.form._method = "PUT";

      this.form
        .post("/app/url/info/" + id)
        .then((res) => {
          this.getResults();
          this.Clear();
          this.s(res.data.message);
          if (this.$refs.fileInput.files.length > 0) {
            this.$refs.fileInput.files.pop();
          }
          delete this.form._method;
        })
        .catch((e) => {
          this.e(e.response.data.message);
        });
    },

    Edit(id) {
      axios
        .get("/app/url/info/" + id + "/edit")
        .then((res) => {
          this.form.id = res.data.urlInfo.id;
          this.form.name = res.data.urlInfo.name;
          this.form.status = res.data.urlInfo.status == 1 ? true : false;
        })
        .catch((e) => {
          this.e(e.response.data.message);
        });
    },

    Delete(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete("/app/url/info/" + id)
            .then((res) => {
              this.s(res.data.message);
              this.getResults();
            })
            .catch((e) => {
              this.e(e.response.data.message);
            });
        }
      });
    },
  },
  computed: {
    permission() {
      return this.$store.getters.getPermissions.url_info;
    },
  },
};
</script>
